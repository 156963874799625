import Section from 'components/Section'
import styles from './DTIProductCards.module.scss'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { useMemoizedContentGetter } from 'utils/contentful'
import { Box, AchieveTheme } from '@achieve/ascend'
import { Typography } from 'components/Contentful'
import { useEffect, useState } from 'react'
import { ThreeProductCard } from 'components/ThreeProductCard'
import useDTIDataForm from 'hooks/useDTIDataForm/useDTIDataForm'

function DTIProductCards({ content }) {
  const [dtiDataForm] = useDTIDataForm()
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const { title, subtitle, grid } = useMemoizedContentGetter(content, ['title', 'subtitle', 'grid'])
  const [listItemsRender, setListItemsRender] = useState([])
  const publishedCards = (listItemsRender || []).filter((item) => Boolean(item))

  useEffect(() => {
    if (dtiDataForm.dataDti !== undefined) {
      const itemsRes = dtiDataForm?.dataDti?.product_rank?.map((item) => item.product) || []
      const itemsDefaultPosition = ['APL', 'ADR', 'AHL']
      const list = []
      for (let i = 0; i < itemsRes.length; i++) {
        const element = itemsRes[i]
        list.push(grid.gridItemsCollection.items[itemsDefaultPosition.indexOf(element)])
      }
      setListItemsRender(list)
    } else {
      setListItemsRender(grid.gridItemsCollection.items)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dtiDataForm])

  return (
    <Section
      backgroundColor={AchieveTheme?.ascend?.colors?.neutral[25]}
      className={styles['section']}
      data-testid="DTIProductCards_section"
    >
      <Box display={'flex'} gap={2} flexDirection={'column'}>
        <Typography
          variant={isMobile ? 'displayMd' : 'displayLg'}
          textAlign={'center'}
          fontWeight="bold"
          content={title?.textContent}
        />
        <Typography
          variant={isMobile ? 'bodySm' : 'displaySm'}
          textAlign={'center'}
          content={subtitle?.textContent}
          className={styles['subtitle']}
          data-testid="DTIProductCards_subtitle"
        />
        <ThreeProductCard
          className={styles['product-card-carousel']}
          content={{ gridItems: publishedCards }}
          isMobile={isMobile}
        />
      </Box>
    </Section>
  )
}
export { DTIProductCards }
export default DTIProductCards
