import React, { useContext } from 'react'
import { pageSectionTypes } from './pageSectionTypes'
import { sectionContentTypes } from './sectionContentTypes'
import { ChildComponentGenerator } from './ChildComponentGenerator'
import { Grid } from '@achieve/ascend'
import { FeatureFlagContext } from 'providers/FeatureFlagProvider'
import { DEFAULT_EXPERIMENT, OFF_EXPERIMENT, UNASSIGNED_EXPERIMENT } from 'constants/experiments'

export function PageHandler({ pageContent, isMobileUA = true, isCrawler = false }) {
  const { featureFlags } = useContext(FeatureFlagContext)

  /**
   * Map through each of the page sections to:
   * 1. Generate child components for page sections. Considers page section experiments and filters
   *    out sections that shouldn't display based on experiment setup
   * 2. Determines loading strategy for page section components based on CMS configuration. Defaults
   *    to 'lazy'
   */
  return (pageContent?.fields?.sections || []).map((section, index) => {

    /** 1. Filter the page sections based on page section experiments */

    // Experiment variation applied to this pageSection
    const pageSectionVar = section?.fields?.experimentVariation
    // Experiment variation assigned to the LD context
    const experimentFlagVar = featureFlags[section?.fields?.experiment?.featureFlagId]
    // Conditions for page section to be returned:
    const showSection =
      // #1 - Default use case for page sections which typically don't have experiments assigned
      !pageSectionVar ||
      // #2 - Assigned page section experiment variation matches the experiment flag value
      pageSectionVar === experimentFlagVar ||
      // #3 - Assigned page section experiment variation is 'Control' and the experiment flag value
      // is 'Off' or 'Unassigned' which should return the 'Control' display (direction from product)
      (pageSectionVar === DEFAULT_EXPERIMENT &&
        [OFF_EXPERIMENT, UNASSIGNED_EXPERIMENT].includes(experimentFlagVar))

    // Return early if this page section should not be displayed
    if (!showSection) return

    /** 2. Determine the loading strategy, generate the page section element and return it to the mapped array */

    // Get the section-specific lazy load value
    const sectionLazyLoad = section?.fields?.lazyLoad

    // If the section-specific lazy load value is not null, use it
    // Otherwise, get the global lazy load value for the current uiComponent
    const globalLazyLoad =
      sectionLazyLoad !== null
        ? sectionLazyLoad
        : pageContent.globalLazyLoadMapping[section?.fields?.uiComponent]

    // If either the global or section-specific lazy load value is true, set lazyLoad to true
    // If both are null, set lazyLoad to false
    const lazyLoad = globalLazyLoad || sectionLazyLoad || false

    return (
      <ChildComponentGenerator
        contentTypes={pageSectionTypes}
        uiComponent={section?.fields?.uiComponent}
        isCrawler={isCrawler}
        lazyLoad={lazyLoad}
        isMobileUA={isMobileUA}
        {...section}
        key={section?.sys?.id || `no-id-${index}`}
      />
    )
  })
}

export function SectionHandler({ sectionContents }) {
  return (sectionContents || []).map((sectionContent) => {
    return (
      <ChildComponentGenerator
        contentTypes={sectionContentTypes}
        uiComponent={sectionContent.fields.uiComponent}
        {...sectionContent}
        key={sectionContent?.sys?.id}
      />
    )
  })
}

export function GridHandler({ gridContents, columns, ...gridProps }) {
  return (gridContents || []).map((gridContent, index) => {
    return (
      <Grid item xs={12 / columns} key={`${gridContent?.sys?.id}-${index}`} {...gridProps}>
        <ChildComponentGenerator
          contentTypes={sectionContentTypes}
          uiComponent={gridContent.fields.uiComponent}
          {...gridContent}
        />
      </Grid>
    )
  })
}
