import { useMemoizedContentGetter } from 'utils/contentful'
import { MediaImageStandard, Typography } from 'components/Contentful'
import Section from 'components/Section'
import { AchieveTheme, Box } from '@achieve/ascend'
import { BLOCKS } from '@contentful/rich-text-types'
import { BREAKPOINTS } from 'constants/viewport'
import useSsrViewportCheck from 'hooks/useSsrViewportCheck/useSsrViewportCheck'

const AboutUsHero = ({ content }) => {
  const { aboutUsHeroTitle, aboutUsHeroSubTitle, aboutUsHeroImage } = useMemoizedContentGetter(
    content,
    ['aboutUsHeroTitle', 'aboutUsHeroSubTitle', 'aboutUsHeroImage']
  )
  const isMobile = useSsrViewportCheck(content?.isMobileUA, BREAKPOINTS.lg)

  const mediaImageContent = !isMobile
    ? aboutUsHeroImage?.mediaContent
    : aboutUsHeroImage?.mobileMediaContent || aboutUsHeroImage?.mediaContent
  return (
    <Section
      data-testid="about-us-hero-section"
      className={
        // styles['section']
        'relative overflow-hidden h-[485px] flex items-center'
      }
      contain={false}
    >
      <Box
        className={
          // styles['about-us-hero-container']
          'h-full w-full'
        }
        data-testid="about-us-hero-background"
      >
        <div
          className={
            // styles['media-img']
            ''
          }
        >
          <MediaImageStandard
            content={mediaImageContent}
            layout="fill"
            objectFit="cover"
            objectPosition="top left"
            priority={true}
            quality={isMobile ? 100 : 75}
            alt={aboutUsHeroImage?.mediaAltText || 'about-hero-image'}
          />
        </div>
        <div
          className={
            // styles['content']
            'h-full w-full flex flex-col justify-center relative [background:linear-gradient(_344.37deg,rgb(0_0_0_/_72.9%)_0%,rgb(0_0_0_/_39.2%)_73.79%,rgb(0_0_0_/_0%)_101.87%_)] px-12 py-0 lg:[background:none] lg:items-end lg:p-0 2xl:pr-[146px]'
          }
        >
          <div
            className={
              // styles['info']
              'lg:w-[485px] lg:max-w-[485px] 2xl:w-[585px] 2xl:max-w-[585px]'
            }
          >
            <Typography
              content={aboutUsHeroTitle?.textContent}
              className={
                // styles['title']
                'text-center lg:text-left'
              }
              color={
                !isMobile
                  ? AchieveTheme?.ascend?.colors?.neutral?.[900]
                  : AchieveTheme?.ascend?.colors?.neutral?.[0]
              }
              fontWeight="bold"
              variantOverride={{ [BLOCKS.HEADING_1]: !isMobile ? 'displayXl' : 'headingLg' }}
              data-testid="about-us-hero-title"
            />
            <Typography
              content={aboutUsHeroSubTitle?.textContent}
              className={
                // styles['subtitle']
                'text-center mt-3 lg:text-left lg:mt-10'
              }
              fontWeight={!isMobile ? 'medium' : 'regular'}
              color={
                !isMobile
                  ? AchieveTheme?.ascend?.colors?.neutral?.[900]
                  : AchieveTheme?.ascend?.colors?.neutral?.[0]
              }
              data-testid="about-us-hero-subtitle"
              variantOverride={{ [BLOCKS.PARAGRAPH]: 'headingMd' }}
            />
          </div>
        </div>
      </Box>
    </Section>
  )
}
export { AboutUsHero }
export default AboutUsHero
